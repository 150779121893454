// Sentry integration
import "./sentry.js"

// import bootstrapBundle from "bootstrap/dist/js/bootstrap.bundle"
// window.bootstrap = bootstrapBundle

// Feather icons are used on some pages
// Replace() replaces [data-feather] elements with icons
import featherIcons from "feather-icons"

import Toastify from 'toastify-js'

import Swal from 'sweetalert2/dist/sweetalert2'

import "./flatpickr.js"

featherIcons.replace()

window.Toastify = Toastify;

window.Swal = Swal;
